import axios from 'axios'
import { partnerStore } from '../stores/partnerStore'
import { memberStore } from '../stores/memberStore'
import { kbStore } from '../stores/kbStore'
import globals from '../globals'

async function uploadFile(
  commData,
  file,
  message,
  progressHandler,
  uploadController,
) {
  try {
    let url = globals.apiHost + '/comm/?sAction=microFilePut'
    let formData = new FormData()
    formData.append('id', commData.comm.id)
    formData.append('members_users_id', memberStore.member.members_users_id)
    formData.append('file_tag', 'attachment')
    formData.append('file', file) // Grab only first file
    formData.append('message', message)
    let response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      signal: uploadController?.signal,
      onUploadProgress: progressHandler,
    })
    return response.data
  } catch (e) {
    console.log('error uploading file: ', e.message)
    throw e
  }
}

async function getKB(id) {
  let url = globals.apiHost + '/comm/getKb?id=' + id
  try {
    let response = await axios.get(url)
    return response.data.kb
  } catch (e) {
    console.log('error getting KB: ', e)
    throw e
  }
}

async function enterCommCall(commId, membersUsersId) {
  let url =
    globals.apiHost +
    '/comm/microEnter/' +
    commId +
    '?members_users_id=' +
    membersUsersId
  try {
    let response = await axios.post(url)
    return response.data
  } catch (e) {
    console.log('error on microEnter: ', e)
    throw e
  }
}

async function enterComm(commId, membersUsersId) {
  let attempts = 0
  let maxRetries = 3
  while (attempts < maxRetries) {
    try {
      const result = await enterCommCall(commId, membersUsersId)
      return result
    } catch (e) {
      attempts++
      if (attempts >= maxRetries) {
        console.log(`Failed after ${attempts} attempts:`, e)
        return e
      }
      console.log(`Attempt ${attempts} failed, retrying...`)
    }
  }
}

async function exitComm(commId, membersUsersId) {
  let url =
    globals.apiHost +
    '/comm/exit/' +
    commId +
    '?members_users_id=' +
    membersUsersId
  try {
    let response = await axios.post(url)
    return response.data
  } catch (e) {
    console.log('e! ', e)
    return e
  }
}

async function getComm(commId, membersUsersId) {
  let url =
    globals.apiHost +
    '/comm/microGet/' +
    commId +
    '?members_users_id=' +
    membersUsersId
  try {
    let response = await axios.post(url)
    return response.data
  } catch (e) {
    console.log('e! ', e)
    return e
  }
}

export { getKB, enterComm, exitComm, getComm, uploadFile }
