import { DateTime } from 'luxon'
// utils/markupParser.js
import MarkdownIt from 'markdown-it'
import DOMPurify from 'dompurify'

import html2canvas from 'html2canvas'

const formatTimestamp = timestamp => {
  if (timestamp) {
    let formattedDiff
    let timeFormat = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
    const dateObj = new Date(timestamp)

    const start = DateTime.fromJSDate(dateObj)
    const end = DateTime.now()
    const diffInMinutes = end.diff(start, 'minutes').toObject()
    const diffInHours = end.diff(start, 'hours').toObject()
    const diffInDays = end.diff(start, 'days').toObject()

    if (diffInMinutes.minutes <= 60) {
      formattedDiff = parseInt(diffInMinutes.minutes) + 'm'
    } else if (diffInHours.hours <= 24) {
      formattedDiff = parseInt(diffInHours.hours) + 'h'
    } else if (diffInDays.days <= 30) {
      timeFormat.month = 'short'
      timeFormat.day = 'numeric'
      formattedDiff = parseInt(diffInDays.days) + 'd'
    } else {
      timeFormat.month = 'short'
      timeFormat.day = 'numeric'
      timeFormat.year = 'numeric'
    }

    const formattedTimestamp = dateObj.toLocaleString('en-US', timeFormat)
    return formattedDiff
      ? formattedTimestamp + ` (${formattedDiff} ago)`
      : formattedTimestamp
  } else {
    return ''
  }
}

const getCookieOptions = ttl => {
  if (!ttl || ttl < 0) ttl = 2592000
  var date = new Date()
  date.setTime(date.getTime() + ttl * 1000)
  return {
    samesite: 'None',
    expires: date,
    secure: true,
  }
}

// https://boomtown.atlassian.net/browse/DEV-4787
// dates from GET v2/issues are in PST, but do not have a TZ in the string
// use Luxon to create a date with the correct TZ
const addTZtoDateTimeString = date => {
  let stringParts = date.split(' ')
  let dateParts = stringParts[0].split('-')
  let timeParts = stringParts[1].split(':')
  // dates in prod are PST; other environments are UTC
  let zone = window.location.href.match('messenger.goboomtown.com')
    ? 'America/Los_Angeles'
    : 'Etc/UTC'
  let dateWithTZ = DateTime.fromObject(
    {
      hour: timeParts[0],
      minute: timeParts[1],
      second: timeParts[2],
      year: dateParts[0],
      month: dateParts[1],
      day: dateParts[2],
    },
    { zone },
  )
  return dateWithTZ
}

// Function to post-process links
function addTargetBlankToLinks(html) {
  const div = document.createElement('div')
  div.innerHTML = html
  const links = div.querySelectorAll('a')
  links.forEach(link => {
    link.setAttribute('target', '_blank')
    link.setAttribute('rel', 'noopener noreferrer')
  })
  console.log(div.innerHTML)
  return div.innerHTML
}

function parseMarkup(input) {
  const markdownParser = new MarkdownIt()
  const html = markdownParser.render(input)
  const purifiedHtml = DOMPurify.sanitize(html)
  const linkifiedHtml = addTargetBlankToLinks(purifiedHtml)
  return linkifiedHtml
}

async function saveDivAsImage(divId) {
  const element = document.getElementById(divId)
  console.log('here is element... ', element)

  // Get the full dimensions of the element
  const elementWidth = element.scrollWidth
  const elementHeight = element.scrollHeight

  return html2canvas(element, {
    scale: 1.5, // Adjust to control resolution
    width: elementWidth,
    height: elementHeight,
    windowWidth: elementWidth,
    windowHeight: elementHeight,
  }).then(canvas => {
    const imgData = canvas.toDataURL('image/png')
    return imgData
  })
}

function blobToFile(blob, filename) {
  // Create a new File object from the Blob
  return new File([blob], filename, { type: blob.type })
}

function base64ToFile(base64String, filename) {
  const arr = base64String.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export {
  formatTimestamp,
  getCookieOptions,
  addTZtoDateTimeString,
  parseMarkup,
  saveDivAsImage,
  blobToFile,
  base64ToFile,
}
