<template>
  <div class="user-identification">
    <screen-header></screen-header>
    <div class="user-identification-screen">
      <user-identification
        class="user-identification-component"
      ></user-identification>
    </div>
  </div>
</template>

<script>
import ScreenHeader from '@/components/ScreenHeader'
import { resolveIssue } from '@/api/issueApi'
import UserIdentification from '../components/UserIdentification'

export default {
  name: 'EndChat',
  inject: [
    'chatStore',
    'partnerStore',
    'issueStore',
    'themeStore',
    'memberStore',
  ],
  props: {},
  data: function () {
    return {
      submitting: false,
      primaryColor: this.themeStore.theme.primaryColor,
    }
  },
  components: { ScreenHeader, UserIdentification },
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.user-identification {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.user-identification-screen {
  margin-top: 96px;
  padding: 0px 30px;
}

.user-identification-header {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #424851;
  margin-top: 20px;
}

.user-identification-component {
  max-width: 70%;
  margin-top: 96px;
}
</style>
