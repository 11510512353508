import { reactive } from 'vue'

const config = reactive({
  fullScreen: false,
  appOpen: false,
  embedded: false,
  sourceURL: '',
  chatSource: 'xmpp',
})

function setConfig(key, value) {
  config[key] = value
}

function removeConfig(key) {
  config[key] = null
}

function clearConfigs() {
  Object.keys(config).forEach(key => {
    config[key] = null
  })
}

export const configStore = {
  config,
  setConfig,
  removeConfig,
  clearConfigs,
}
