<template>
  <div class="end-chat">
    <screen-header></screen-header>
    <div class="end-chat-screen">
      <img src="@/assets/end-chat-icon.svg" class="end-chat-icon" />
      <div class="end-chat-header">Are you sure you want to end this chat?</div>
      <div class="end-chat-body">
        <p>This will end the current conversation.</p>
      </div>
      <div class="buttons">
        <PrimaryCTA
          v-if="chatStore.state.chatSource == 'bot'"
          class="end-chat-button"
          @click="endBotChat"
          text="Yes, End Chat"
          :submitting="submitting"
        ></PrimaryCTA>
        <PrimaryCTA
          v-if="chatStore.state.chatSource != 'bot'"
          class="end-chat-button"
          @click="endChat"
          text="Yes, End Chat"
          :submitting="submitting"
        ></PrimaryCTA>
        <SecondaryCTA
          class="back-button"
          @click="$router.go(-1)"
          text="No, Back to Chat"
          :submitting="submitting"
        ></SecondaryCTA>
      </div>
    </div>
  </div>
</template>

<script>
import ScreenHeader from '@/components/ScreenHeader'
import { resolveIssue } from '@/api/issueApi'
import chatService from '@/services/chatService'
import SecondaryCTA from '../components/SecondaryCTA'
import PrimaryCTA from '../components/PrimaryCTA'

export default {
  name: 'EndChat',
  inject: ['chatStore', 'partnerStore', 'issueStore', 'memberStore'],
  props: {},
  data: function () {
    return {
      submitting: false,
    }
  },
  components: { ScreenHeader, SecondaryCTA, PrimaryCTA },
  computed: {},
  mounted() {},
  methods: {
    async endChat() {
      this.submitting = true
      try {
        const response = await resolveIssue(this.issueStore.issue.id)
        if (response.success) {
          this.sendEndChatMessage()
          this.issueStore.markInactive()
          this.issueStore.setIssue({
            status: 'Resolved',
            timeResolved: new Date(),
          })
          localStorage.setItem('botChat', JSON.stringify([]))
          this.$router.go(-1)
        } else {
          this.submitting = false
        }
      } catch (error) {
        //todo: error notification?
        this.submitting = false
      }
    },
    async endBotChat() {
      this.chatStore.resetChat()
      localStorage.setItem('botChat', JSON.stringify([]))
      this.$router.push({
        name: 'HomeScreen',
      })
    },
    sendEndChatMessage() {
      const endChatMessage = {
        message: '',
        timestamp: new Date().toISOString(),
        secret: this.chatStore.endChatMessageSecret,
        channel: 'chat',
        service: 'messenger',
        ttl: null,
        context: {
          object: 'issues',
          object_id: this.issueStore.issue.id,
          from: 'members_users:' + this.memberStore.member.members_id,
          from_name: this.issueStore.issue.members_users_name,
          from_user: this.issueStore.issue.members_users_id,
          source: 'messenger',
        },
      }
      chatService.sendMessage(JSON.stringify(endChatMessage))
    },
  },
}
</script>

<style scoped lang="scss">
.end-chat {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.end-chat-icon {
  margin-top: 26px;
}
.end-chat-screen {
  padding: 0px 30px;
}
.end-chat-header {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #424851;
  margin-top: 20px;
}

.buttons {
  position: absolute;
  bottom: 30px;
  right: 30px;
  left: 30px;
}

.end-chat-body {
  color: #424851;
  margin-top: 15px;
}
.back-button {
  margin-top: 20px;
}
</style>
