<template>
  <div class="chat-with-human">
    <screen-header></screen-header>
    <div class="chat-with-human-screen">
      <!-- <img src="@/assets/chat-with-human-icon.svg" class="chat-with-human-icon" /> -->
      <div class="chat-with-human-icon">
        <span class="bt-icon bt-icon-user"></span>
      </div>
      <div class="chat-with-human-header">
        Would you like to chat with a live representative?
      </div>
      <div class="chat-with-human-body">
        <p>
          Your existing conversation will be sent to the representative to help
          answer your questions.
        </p>
      </div>
      <div class="buttons">
        <PrimaryCTA
          v-if="chatStore.state.chatSource == 'bot'"
          @click="goToUserIdentification"
          text="Chat With Representative"
          :submitting="submitting"
        ></PrimaryCTA>
        <SecondaryCTA
          class="back-button"
          @click="$router.go(-1)"
          text="No, Back to Chat"
          :submitting="submitting"
        ></SecondaryCTA>
      </div>
    </div>
  </div>
</template>

<script>
import ScreenHeader from '@/components/ScreenHeader'
import { resolveIssue } from '@/api/issueApi'
import chatService from '@/services/chatService'
import SecondaryCTA from '../components/SecondaryCTA'
import PrimaryCTA from '../components/PrimaryCTA'

export default {
  name: 'EndChat',
  inject: [
    'chatStore',
    'partnerStore',
    'issueStore',
    'themeStore',
    'memberStore',
  ],
  props: {},
  data: function () {
    return {
      submitting: false,
      primaryColor: this.themeStore.theme.primaryColor,
    }
  },
  components: { ScreenHeader, SecondaryCTA, PrimaryCTA },
  computed: {},
  mounted() {},
  methods: {
    // todo: OR send chat message
    goToUserIdentification() {
      //   this.chatStore.addMessage({
      //     message: 'Enter your information to chat with a live representative',
      //     name: 'Generated by OvationCXM AI',
      //     resource: 'ovation-bot',
      //     avatar: '/images/bot-avatar.png',
      //     showUserIdentification: true,
      //   })
      //   this.$router.go(-1)

      this.$router.replace({ name: 'UserIdentification' })
    },
  },
}
</script>

<style scoped lang="scss">
.chat-with-human {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.chat-with-human-icon {
  margin-top: 26px;
}
.chat-with-human-screen {
  padding: 0px 30px;
}
.chat-with-human-header {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #424851;
  margin-top: 20px;
}

.buttons {
  position: absolute;
  bottom: 30px;
  right: 30px;
  left: 30px;
}

.chat-with-human-body {
  color: #424851;
  margin-top: 15px;
}
.back-button {
  margin-top: 20px;
}

.chat-with-human-icon {
  font-size: 36px;
  color: #424851;
  display: inline-block;
  height: 48px;
  width: 48px;
  text-align: center;
  padding-top: 4px;

  border: 1px solid gray;
  border-radius: 6px;
  color: v-bind(primaryColor);
}
</style>
