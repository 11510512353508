var merge = require('lodash/merge')
import { reactive, computed, readonly } from 'vue'
import Cookie from 'js-cookie'
import { partnerStore } from './partnerStore'
import mockPartnerConfig from '../../tests/data/mockPartnerConfig'
import { getCookieOptions } from '../utils/helpers'

const member = reactive({
  guestId: `guest-${Math.floor(Math.random() * 0xffffff)
    .toString(16)
    .padStart(6, '0')}`,
})

function setMember(memberData) {
  merge(member, memberData)
  setCookie(memberData)
}

function setCookie(memberData) {
  let existingCookie = Cookie.get('memberData')
  let existingMemberData
  try {
    existingMemberData = JSON.parse(existingCookie)
  } catch (e) {
    existingMemberData = {}
  }

  existingMemberData[partnerStore.config.integrationId] = {
    members_id: memberData.members_id,
    members_users_id: memberData.members_users_id,
    members_locations_id: memberData.members_locations_id,
    member_user: { first_name: memberData.member_user.first_name },
  }

  Cookie.set(
    'memberData',
    JSON.stringify(existingMemberData),
    getCookieOptions(),
  )
}

function unsetMember() {
  let existingCookie = Cookie.get('memberData')
  let existingMemberData
  try {
    existingMemberData = JSON.parse(existingCookie)
  } catch (e) {
    existingMemberData = {}
  }
  delete existingMemberData[partnerStore.config.integrationId]
  Cookie.set(
    'memberData',
    JSON.stringify(existingMemberData),
    getCookieOptions(),
  )
  Object.keys(member).forEach(key => {
    member[key] = null
  })
  member.guestId = `guest-${Math.floor(Math.random() * 0xffffff)
    .toString(16)
    .padStart(6, '0')}`
}

export const memberStore = readonly({
  member,
  setMember,
  unsetMember,
})
